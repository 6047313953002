function download({ fileName, content, type }) {
  const blob = type ? new Blob([content], { type: type }) : new Blob([content]);
  let link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  link.remove();
}

export default {
  download
};
